.grouplisting {
  overflow: auto;
}

.grouplisting__wall {
  position: relative;
  column-count: 2;
  column-gap: 15px;
}

.grouplisting__search {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $lightBorderColor;
  input {
    width: 100%;
    max-width: none;
    background-color: $lightBorderColor;
    border: none;
    outline: none;
    padding: 8px 15px;
    font-size: 18px;
  }
}

.grouplisting__single {
  position: relative;
  margin-bottom: 15px;
  break-inside: avoid;
  box-shadow: $defaultBoxShadow;
  border: 1px solid $lightBorderColor;
}

.grouplisting__checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -7px;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  border: 1px solid $lightBorderColor;
}

.grouplisting__item {
  position: relative;
  padding: 15px;
  margin-right: 10px;
  min-width: 50px;
  white-space: nowrap;
  cursor: pointer;
  &.grouplisting__item--active {
    .grouplisting__checkbox {
      background-color: green;
    }
  }
  &.grouplisting__item--break {
    &:before {
      content: '';
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      height: 1px;
      background-color: $lightBorderColor;
    }
  }
}

.grouplisting__avatar,
.grouplisting__title,
.grouplisting__checkbox {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

.grouplisting__avatar {
  margin-right: 10px;
}
