.balance-table {

  .balance-table__cell {
    padding-left: 15px;
    padding-right: 15px;
  }

  table {
    width: 100%;
    border-spacing: 0 5px;

    .balance-table__row {
      cursor: pointer;
      background-color: #fff;

      .balance-table__cell {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      td {
        font-size: 16px;
        font-family: $mainFontBold;
      }
    }

    .balance-table__extended-row {

      tr {
        &:first-child {

          td {
            padding-top: 10px;
          }

          .balance-table__cell {
            &:before {
              top: 4px;
            }
          }

        }

        &:last-child {
          td {
            padding-bottom: 15px;
          }

          .balance-table__cell {
            &:before {
              bottom: 4px;
            }
          }

        }
      }

      td {

        &:first-child {

          .balance-table__cell {
            &:before {
              content: none;
            }
          }
        }

        .balance-table__cell {
          position: relative;
          padding-top: 4px;
          padding-bottom: 4px;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: -8px;
            left: 0;
            background-color: #111;
            width: 1px;
          }

          &.balance-table__cell--empty {

            &:before {
              content: none;
            }

          }

        }

        &.no-border {
          border-right-color: transparent;
        }
      }

    }

    .balance-table__negative {
      color: $tableNegative;
    }

    .balance-table__positive {
      color: $tablePositive;
    }

  }
}
