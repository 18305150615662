.balance-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .balance-box__bordered-area {
    display: flex;
    width: 100%;
    margin: 0 10px;
    padding: 15px 15px;
    align-items: center;
    justify-content: space-between;
    border: 3px solid $greenColorPrimary;

    @media(min-width: $md) {
      width: $balanceInputsWidth;
    }
  }

  .balance-box__label {
    padding-right: 10px;
    min-width: 100px;
    flex-shrink: 1;
    text-align: right;
    text-transform: uppercase;
    color: $greenColorPrimary;
  }

  .balance-box__value {
    text-align: center;
    font-size: 32px;
    flex-grow: 1;
  }
}
