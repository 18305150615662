.task-group__list {
  .task-group-item__icon {
    width: 100%;
    height: 120px;
    margin-bottom: 5px;
  }
}

.task-group__item {
  margin-bottom: 30px;
  cursor: pointer;
}

.task-group-item__inner {
  padding: 5px 15px 40px;
  padding-bottom: 40px;
  border: 1px solid $groupBorderColor;
  transition: border-color 0.3s ease-in-out;

  .icon--check {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 105%;
    transition: background-image 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    overflow: hidden;
  }

  &:hover {
    border-color: $greenColorPrimary;

    .icon--check {
      background-color: $greenColorPrimary;
      background-image: url('~assets/img/icons/icon_check.svg?fill=#FFFFFF');
    }
  }
}

.task-group-item__details {
  margin: 0 auto;
  max-width: 280px;
  line-height: 1.4;
}

.task-group-item__name {
  color: $fontColorDark;
  font-family: $mainFontRegular;
  font-size: 16px;
}

.task-group-item__description {
  color: $fontColorGrey;
  font-family: $mainFontLight;
  font-size: 14px;
}
