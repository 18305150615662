.selectionboxes {
  max-width: 900px;
  margin: 0 -15px;
}

.selectionboxes__item {
  width: 100%;
  min-height: 140px;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  cursor: pointer;
  @media(min-width: $md) {
    height: 260px;
  }

  &.selectionboxes__item--by-two {
    @media(min-width: $md) {
      width: 50%;
      float: left;
    }
  }
  &.selectionboxes__item--horizontal {
    @media(min-width: $md) {
      text-align: left;

      .selectionboxes__inner {
        display: flex;
        align-items: center;
      }

      .selectionboxes__icon {
        margin-right: 15px;
        flex-shrink: 0;
      }

    }
  }
}

.selectionboxes__inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  border: 1px solid $lightBorderColor;
}

.selectionboxes__icon {
  display: inline-block;
  width: 80px;
  height: 80px;
  @media(min-width: $md) {
    width: 150px;
    height: 150px;
  }
  background-size: contain;
  background-repeat: no-repeat;
  &.selectionboxes__icon--withdraw {
    background-image: url('~assets/img/icons/icon_transactions_withdraw.svg');
  }
  &.selectionboxes__icon--deposit {
    background-image: url('~assets/img/icons/icon_transactions_deposit.svg');
  }
  &.selectionboxes__icon--transaction {
    background-image: url('~assets/img/icons/icon_transactions_transaction.svg');
  }
}

.selectionboxes__title {
  color: $fontColorDark;
  font-size: 24px;
}
