.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .25);
  z-index: 4;

  &.overlay--primary-color {
    background-color: $primaryColor;
  }

}
