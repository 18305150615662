.anim-fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.anim-fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.anim-fade-exit {
  opacity: 1;
}
.anim-fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.anim-opacity-enter {
  opacity: 0;
}
.anim-opacity-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.anim-opacity-exit {
  opacity: 1;
}
.anim-opacity-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

.anim-lefttoright-enter {
  transform: translateX(-100%);
}
.anim-lefttoright-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.anim-lefttoright-exit {
  transform: translateX(0);
}
.anim-lefttoright-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms;
}

.anim-righttoleft-enter {
  transform: translateX(100%);
}
.anim-righttoleft-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.anim-righttoleft-exit {
  transform: translateX(0);
}
.anim-righttoleft-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}
