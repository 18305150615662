.tabs {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  max-width: 670px;

  .tabs__item {
    flex-grow: 1;
    padding: 15px;
    font-size: 16px;
    text-align: center;
    border: 1px solid $fontColorDark;
    cursor: pointer;
    transition: color ease .3s, background-color ease .3s;
    background-color: #fff;

    &.tabs__item--active {
      color: #fff;
      background-color: $primaryColor;
      border-color: transparent;
    }

    &:hover,
    &:focus,
    &:active {
      &:not(.tabs__item--active) {
        color: #fff;
        background-color: $primaryColorLight;
      }
    }

    &:first-child {
      border-bottom-left-radius: $mainBorderRadius;
      border-top-left-radius: $mainBorderRadius;
    }

    &:last-child {
      border-bottom-right-radius: $mainBorderRadius;
      border-top-right-radius: $mainBorderRadius;
    }

  }
}
