.stage-line {
  th {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 15px;
      height: 15px;
    }
  }
}
