.games-list {

  .games-list__header {
    @media(min-width: $md) {
      display: flex;
      flex-direction: row-reverse;
    }
    margin: 20px 0;
  }

  .games-list__tabs {
    padding: 0 15px;
    flex-grow: 1;
  }

  .games-list__pagination {
    margin: 30px 0;
  }

  .games-list__button {
    text-align: right;

    @media(max-width: $md - 1) {
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }

  .games-list__loader {
    margin-top: 80px;
  }

  table {
    td {
      @media(max-width: 767px) {
        font-size: 12px;
      }
    }
  }

}
