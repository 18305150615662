.profile {
  position: relative;
  cursor: pointer;
}

.profile__inner,
.profile__image {
  display: inline-block;
  vertical-align: middle;
}

.profile__inner {
  position: relative;
  font-size: 15px;
  white-space: nowrap;

  + .profile__image {
    margin-left: 15px;
  }
}

.profile__chevron {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  @include angle(
          $position: bottom,
          $size: 7px,
          $color: $greenColorPrimary,
          $height: 1px
  );
}

.profile__name {
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  color: $greenColorPrimary;
  white-space: normal;
}
