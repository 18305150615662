.iframe-container {
  position: relative;
  width: 100%; /* Full width */
  padding-top: 56.25%; /* Aspect ratio of 16:9 (9 / 16 = 0.5625) */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
