@import '~assets/style/variables.scss';

.error-toast {
  &.Toastify__toast {
    background-color: $redColorPrimary;
    color: #fff;

    .Toastify__toast-body {
      font-family: $mainFontBold;
    }

    .Toastify__progress-bar {
      background: $primaryColor !important;
    }

    .Toastify__close-button {
      & > svg {
        fill: #fff !important;
      }
    }
  }
}

.success-toast {
  &.Toastify__toast {
    background-color: $greenColorPrimary;
    color: #fff;

    .Toastify__toast-body {
      font-family: $mainFontBold;
    }

    .Toastify__progress-bar {
      background: #fff !important;
    }

    .Toastify__close-button {
      & > svg {
        fill: #fff !important;
      }
    }
  }
}
