.control-button {
  padding: 5px 12px;
  border: none;
  box-shadow: none;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 13px;
  font-family: $mainFontBold;

  @media(max-width: $md - 1) {
    width: 56px;
    height: 56px;
  }

  &.control-button__orange {
    background-color: $timelineActiveColor;
  }

  &.control-button--disabled {
    @include button-disabled;
  }

  &.control-button__red {
    background-color: $redColorPrimary;
    color: #fff;
  }
  &.control-button__green {
    background-color: $greenColorPrimary;
  }

  &.control-button__main {
    padding: 0 33px;
  }

  .control-button__label {
    display: block;
  }
}
