@import '~assets/style/variables.scss';

@mixin triangle($position: bottom, $size: 12px, $color: $primaryColor, $topMargin: 0) {
  display: inline-block;
  width: 0;
  height: 0;
  margin-top: $topMargin;
  @if $position==top {
    border-top: #{$size} solid transparent;
    border-right: #{$size} solid transparent;
    border-bottom: #{$size} solid $color;
    border-left: #{$size} solid transparent;
  } @else if $position==right {
    border-top: #{$size} solid transparent;
    border-right: #{$size} solid transparent;
    border-bottom: #{$size} solid transparent;
    border-left: #{$size} solid $color;
  } @else if $position==bottom {
    border-top: #{$size} solid $color;
    border-right: #{$size} solid transparent;
    border-bottom: #{$size} solid transparent;
    border-left: #{$size} solid transparent;
  } @else {
    border-top: #{$size} solid transparent;
    border-right: #{$size} solid $color;
    border-bottom: #{$size} solid transparent;
    border-left: #{$size} solid transparent;
  }
}

@mixin angle($position: bottom, $size: 10px, $color: $mainFontColor, $height: 2px) {
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    background-color: $color;
  }
  @if $position == top {
    &:before {
      transform: translate(-85%, -50%) rotate(-45deg);
      width: #{$size};
      height: #{$height};
    }
    &:after {
      transform: translate(-15%, -50%) rotate(45deg);
      width: #{$size};
      height: #{$height};
    }
  }
  @if $position == right {
    &:before {
      transform: translate(-50%, -85%) rotate(-45deg);
      width: #{$height};
      height: #{$size};
    }
    &:after {
      transform: translate(-50%, -15%) rotate(45deg);
      width: #{$height};
      height: #{$size};
    }
  }
  @if $position == bottom {
    &:before {
      transform: translate(-85%, -50%) rotate(45deg);
      width: #{$size};
      height: #{$height};
    }
    &:after {
      transform: translate(-15%, -50%) rotate(-45deg);
      width: #{$size};
      height: #{$height};
    }
  }
  @if $position == left {
    &:before {
      transform: translate(-50%, -85%) rotate(45deg);
      width: #{$height};
      height: #{$size};
    }
    &:after {
      transform: translate(-50%, -15%) rotate(-45deg);
      width: #{$height};
      height: #{$size};
    }
  }
}

@mixin button-disabled() {
  background-color: #dcd8dc !important;
  opacity: 0.6;
  cursor: default;
  outline: none;
  &:focus,
  &:hover,
  &:active {
    opacity: 0.6;
    background-color: #dcd8dc;
  }
}
