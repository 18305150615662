.admin-actions-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: #fff;
  padding: 20px;
  min-width: 150px;
  border-radius: $mainBorderRadius;
  box-shadow: 0 3px 6px #00000029;
  text-align: center;

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 15px;

      &,
      > * {
        display: block;
        font-family: $mainFontMedium;
        font-size: 21px;
        color: $fontColorNormal;
        text-decoration: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }
  }



}
