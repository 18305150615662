$mainDatePickerColor: $primaryColor;

body {
  .m-calendar .toolbar,
  .m-calendar .toolbar .current-date,
  .m-input-moment .options button,
  .m-calendar thead td,
  .m-calendar .current-day,
  .m-time .time-text,
  .m-time .separater {
    color: $mainDatePickerColor;
  }

  .m-time .time,
  .m-calendar .toolbar button,
  .m-input-moment .options button.is-active,
  .m-calendar tbody td:hover,
  .m-input-moment .btn-save,
  .u-slider-time .value {
    background-color: $mainDatePickerColor;
  }

  .u-slider-time .handle:after,
  .m-input-moment .options button,
  .m-calendar .toolbar button {
    border-color: $mainDatePickerColor;
  }

  .m-input-moment {
    width: $datepickerWidth;
  }

  .ion-ios-arrow-right {
    @include angle($position: right, $size: 10px, $color: #fff, $height: 2px)
  }
  .ion-ios-arrow-left {
    @include angle($position: left, $size: 10px, $color: #fff, $height: 2px)
  }
}
