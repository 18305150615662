$cellSpacings: 16px;

.primary-table {

  table {
    width: 100%;
    border-spacing: 0;
  }

  tr {

    &:first-child {

      td {
        border-top: 8px solid $wrapperBackground;
      }
    }
  }

  th {
    padding: 15px $cellSpacings;
    background-color: #fff;
    border-bottom: 2px solid $primaryColor;
  }

  td {
    padding: 8px $cellSpacings;
    background-color: #fff;
    border-bottom: 2px solid $wrapperBackground;
  }

}
