.progressboard {
  //box-shadow: $defaultBoxShadow;
}

.progressboard__inner {
  width: 100%;
}

.progressboard__ongoing {
  display: inline-block;
  padding: 5px 5px;
  color: #fff;
  font-size: 12px;
  background-color: $noticeColor;
}

.progressboard__single {
  position: relative;
  background-color: #ffff; //box-shadow: inset 11px 0 8px -10px #CCC, inset -11px 0 8px -10px #CCC;
  cursor: pointer;
  transition: all ease 0.2s;
  &.open {
    margin: 5px -15px;
    padding: 0 15px;
    box-shadow: $defaultBoxShadow;
    .progressboard__name {
      color: $fontColorNormal;
    }
    .progressboard__clock {
      color: $noticeColor;
    }
    .progressboard__clock {
      left: 30px;
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    box-shadow: $defaultBoxShadow;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}

.progressboard__clock {
  position: absolute;
  top: 20px;
  left: 15px;
  color: $fontColorGrey;
  font-family: $mainFontLight;
  transition: left ease 0.2s;
}

.progressboard__content {
  padding-left: 140px;
}

.progressboard__innercontent {
  position: relative;
  padding: 20px 0;
  &:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: $noticeColor;
  }
}

.progressboard__external {
  position: relative;
  color: $subTableNegative;
  &:before {
    content: '';
    position: absolute;
    left: -17px;
    height: 100%;
    width: 6px;
    background-color: $subTableNegative;
  }

  &:after {
    content: '';
    position: absolute;
    left: -15px;
    height: 100%;
    width: 1px;
    background-color: #fff;
  }
}

.progressboard__name {
  margin-bottom: 20px;
  font-family: $mainFontRegular;
  color: $fontColorGrey;
}

.progressboard__timingssingle {
  float: left;
  width: 33.333%;
}

.progressbar__label {
  color: $fontColorGrey;
  font-size: 16px;
  font-family: $mainFontRegular;
}

.progressbar__time {
  color: $fontColorDark;
  font-size: 21px;
  font-family: $mainFontRegular;
}
