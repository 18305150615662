$dashBoardSidebarWidth: 250px;

.playerdashboard--with-players-sidebar {
  @media(min-width: $md) {
    margin-right: $playersSidebarWidth;
  }
}

.dashboard {
  position: relative;
}

.dashboard__top {
  margin-top: 50px;
}

.dashboard__comp {
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashboard__line {
  margin-bottom: 40px;
}

.dashboard__header {
  margin-bottom: 15px;
}

.dashboard__header--underlined {
  padding-bottom: 5px;
  border-bottom: 2px solid $primaryColor;
}

.dashboard__heading {
  color: $fontColorDark;
  font-size: 18px;
  font-family: $mainFontRegular;
  line-height: 1.4;

  + .dashboard__subheading {
    color: $fontColorGrey;
    font-size: 16px;
    font-family: $mainFontLight;
    line-height: 1.4;
  }
}

@media (min-width: 992px) {
  .dashboard__sidebar {
    margin-top: 0;
    height: 100%;
  }
}


.dashboard__timing {
  position: relative;
  padding-right: 60px;

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
}
