.auth__footer {
  text-align: center;

  .footer__item {
    position: relative;
    display: inline-block;
    font-family: $mainFontLight;
    font-size: 13px;
    line-height: 1.15;
    color: #ffffff;

    + .footer__item {
      padding-left: 10px;
      margin-left: 10px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 12px;
        background-color: #ffffff;
        transform: translate(0, -50%);
      }
    }
  }

  .footer__item--link {
    border-bottom: 1px solid #ffffff;
  }
}

@media (min-height: 720px) {
  .auth__footer {
    position: absolute;
    bottom: 80px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
  }
}
