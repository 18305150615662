@import '~assets/style/variables.scss';

$timelineLabelSpaceFromIndicator: 15px;
$spaceFromBar: 50px;

.timeline {

  .timeline__label {
    text-transform: uppercase;
    font-family: $mainFontBold;
  }

  .timeline__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .timeline__label {
      margin-bottom: 5px;
    }

    .timeline__title {
      font-size: 18px;
      margin-bottom: 14px;
    }
  }

  .timeline__bar {
    margin-bottom: $spaceFromBar;
    position: relative;
    height: 38px;
    background-color: #ace2fb;
    border-bottom: 3px solid $timelineActiveColor;

    .timeline__active-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $primaryColor;
      transition: width ease .3s;
    }

    .timeline__stage {
      position: relative;
      display: inline-block;
      float: left;
      height: 100%;
      transition: width ease .3s;

      .timeline__indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1px;
        width: 2px;

        &.first {
          left: -1px;
          right: auto;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 2px;
          background-color: $timelineActiveColor;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: -7px;
          left: 50%;
          height: 12px;
          width: 12px;
          background-color: #fff;
          border-radius: 100%;
          border: 3px solid $timelineActiveColor;
          transform: translateX(-50%);
        }

        &.active {
          &:before {
            bottom: -12px;
          }
        }

      }

    }

    .timeline__label {
      position: absolute;
      top: 100%;
      left: -50%;
      transform: translate(-50%, $timelineLabelSpaceFromIndicator);
    }

    .timeline__final-point {
      position: absolute;
      top: 0;
      bottom: 0;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: -13px;
        left: 0;
        background-color: #111;
        width: 4px;
      }

      .timeline__label {
        left: 0;
        right: auto;
        transform: translateY($timelineLabelSpaceFromIndicator);
      }

      &.last {
        left: auto;
        right: 0;

        &:before {
          left: -4px;
        }

        .timeline__label {
          left: auto;
          right: 0;
        }

      }



    }

  }

}
