.lightbox {
  display: none;
  position: fixed;
  top: var(--header-height);
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(30, 30, 30, 0.8);
  z-index: $zIndexLightbox;

  @media(min-width: $md) {
    left: $sidebarWidth;
  }

  &.active {
    display: block;
  }

  &.lightbox--closed-top {
    top: 0!important;
  }

  &.lightbox--full-screen {
    top: 0;
    left: 0;

    .modal__inner {
      top: 50%;
      left: 80px;
      right: 80px;
      transform: translateY(-50%);
    }
  }

}

.lightbox--primary {
  z-index: 2;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.modal--fixed-bottom {
    bottom: 150px;
    .modal__inner {
      bottom: 0;
    }
  }

  &.modal--datepicker {
    .modal__inner {
      max-width: $datepickerWidth;
      padding: 0;
    }
  }

  &.modal--error {
    .modal__inner {
      border: 3px solid $redColorPrimary;
    }
  }

  @media(max-width: $md - 1) {
    &.modal--mob-full {

      .modal__inner {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }

    }
  }

}

.modal__inner {
  background: #fff;
  position: absolute;
  right: 10%;
  left: 10%;
  top: 5%;
  padding: 20px;
  max-width: 920px;
  max-height: 720px;
  margin: 0 auto;
  overflow-y: auto;
  border-radius: 6px;

  @media(min-width: $md) {
    border: 1px solid $primaryColor;
  }

  &.modal__inner--nonfixed-width {
    max-width: none;
  }

  &.modal__inner--sm {
    max-width: 350px;
  }

  &.modal__inner--lg {
    max-width: 1200px;
  }

  &.modal__inner--content-width {
    left: auto;
    right: 50%;
    transform: translateX(50%);
  }

  &.modal__inner--error {
    border: 2px solid $redColorPrimary;
  }
}

.modal__content-wrap {
  display: flex;
  align-items: center;
}

.modal__header {
  margin-right: 20px;

  .icon {
    width: 100%;
    min-width: 80px;
    height: 80px;

    @media(min-width: $md) {
      min-width: 150px;
      height: 130px;
    }

    + .modal__heading {
      margin-top: 5px;
    }
  }
}

.modal__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  &.modal__content--fixed-footer {

    .modal__content-wrap {
      padding-bottom: 65px;
    }
  }
}

.modal__heading {
  font-family: $mainFontMedium;
  font-size: 20px;
  line-height: 1.4;
}

@media (min-width: 992px) {
  .modal__inner {
    right: 15%;
    left: 15%;
  }
}

.modal-content__footer {

  @media(max-width: $md - 1) {
    &.modal-content__footer--task {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding-bottom: 10px;
      padding-top: 10px;
      background-color: #fff;
    }
  }

}

.modal-content__inner {
  font-size: 15px;
  line-height: 1.4;
  overflow: auto;
  @media(min-width: $md) {
    font-size: 20px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  + .modal-content__footer {
    margin-top: 15px;
  }
}

.modal__buttons {
  text-align: right;
}
