.user-group {
  margin-bottom: 5px;
  border-radius: 6px;
  break-inside: avoid;
  border: 1px solid $thirdColor;

  .user-group__header {
    position: relative;
    font-size: 16px;
    text-transform: uppercase;
    font-family: $mainFontBold;

    &:after {
      content: '';
      position: absolute;
      left: 12px;
      right: 12px;
      height: 1px;
      background: $thirdColor;

      @media(min-width: $md) {
        left: 30px;
        right: 30px;
      }
    }

    &.user-group__header--no-players {
      border-bottom: none;
      padding-bottom: 0;
    }

    .user-group__item {
      margin-top: 0;
    }
  }

  .user-group__item {
    display: flex;
    padding: 8px 6px;
    align-items: center;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s;

    @media(min-width: $md) {
      padding: 14px 13px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:focus,
    &:hover,
    &:active {
      background-color: rgba($thirdColor, 0.2);
    }

    &.selected {
      background-color: rgba($thirdColor, 0.6);
      color: #fff;
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;

      &:focus,
      &:hover,
      &:active {
        background-color: transparent;
      }
    }
  }

  .user-group__info {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    font-size: 12px;

    @media(min-width: $md) {
      font-size: 16px;
    }
  }

  .user-group__avatar {
    margin-right: 15px;

    @media(max-width: $md - 1) {
      .avatar {
        width: 36px;
        height: 36px;
      }
    }
  }
}
