.mob-transactions-details {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: $defaultBoxShadow;

  .mob-transactions-details__item {
    padding: 0 15px;
    flex: 1 1 0;

    border-left: 1px solid $fontColorNormal;

    @media(max-width: $xs - 1) {
      margin-bottom: 10px;
      border-left: none;
      flex: 0 0 50%;
    }

    &:first-child {
      border-left: none;
    }
  }

  .mob-transactions-details__label {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .mob-transactions-details__values {
    font-size: 18px;
  }
}
