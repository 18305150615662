$checkboxSize: 16px;

.playerlisting {
  margin-bottom: 15px;
}

.playerlisting__single {
  position: relative;
  float: left;
  width: 33.3333%;
  min-height: 55px;
  padding: 0 15px;
  cursor: pointer;
  .playerlisting__checkbox {
    transition: all 0.2s ease;
    background-color: transparent;
  }

  &.active,
  &:hover {
    .playerlisting__checkbox {
      background-color: $noticeColor;
      &:before,
      &:after {
        position: absolute;
        content: '';
        background-color: #fff;
        height: 2px;
      }
      &:before {
        transform: rotate(-50deg);
        margin-left: 4px;
        margin-top: 6px;
        width: 8px;
      }
      &:after {
        transform: rotate(30deg);
        width: 5px;
        margin-top: 7px;
        margin-left: 2px;
      }
    }
  }

  &.playerlisting__single--fullwidth {
    width: 100%;
    float: none;
  }

  &.playerlisting__single--nocheck {
    padding: 0 0;
  }

  &.playerlisting__single--hidden {
    cursor: default;
    .playerlisting__checkbox {
      display: none;
      &:before,
      &:after {
        content: none;
      }
    }
  }
}

.playerlisting__checkbox {
  width: $checkboxSize;
  height: $checkboxSize;
  margin-right: 5px;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid $noticeColor;
}

.playerlisting__set {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.playerlisting__checkbox,
.playerlisting__avatar {
  display: inline-block;
  vertical-align: middle;
}

.playerlisting__inner {
  display: table;
  min-height: 55px;
  width: 100%;
  padding-left: $avatarSize + 14;
}

.playerlisting__name {
  display: table-cell;
  color: $fontColorNormal;
  font-size: 19px;
  font-family: $mainFontRegular;
  vertical-align: middle;
  line-height: 1.4;
}

.playerlisting__code {
  color: $fontColorGrey;
  font-size: 16px;
}

.input-wrapper {
  position: relative;
  margin-bottom: 20px;
  padding-right: 130px;
}

.input-wrapper__button {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 120px;
}
