.customlisting {
  margin-top: 20px;
}

.customlisting__item {
  position: relative;
  margin-bottom: 10px;
  height: 120px;
  border: 1px solid $customLineColor;
  border-left-color: transparent;
  cursor: pointer;

  &.customlisting__item--boxed {
    border-left-color: $customLineColor;
  }
  &.customlisting__item--padding {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    .customlisting__content {
      padding-left: 0;
    }
  }
  &.customlisting__item--clickable {
    cursor: pointer;
  }
  &.customlisting__item--backgrounded {
    background-color: $wrapperBackground;
    border-left-color: $customLineColor;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-color: $noticeColor;
      height: 40px;
    }
  }
}

.customlisting__avatar {
  position: absolute;
  left: -1px;
  bottom: -1px;
  top: -1px;
  &.customlisting__item--static {
    position: relative;
    left: 0;
    bottom: 0;
    top: 0;
    display: inline-block;
  }
}

.customlisting__inneravatar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 120px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.customlisting__content {
  padding-top: 10px;
  padding-left: 130px;
  padding-bottom: 30px;
  font-family: $mainFontRegular;
  font-family: $fontColorNormal;
}

.customlisting__link {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding-left: 100px;
  font-size: 15px;
  font-family: $mainFontBold;
  color: $primaryColor;
  cursor: pointer;
  &:focus,
  &:hover {
    color: $primaryColorHover;
  }
}
