.numeric-selection__list {
  white-space: nowrap;
}

.numeric-selection__item {
  position: relative;
  display: inline-block;
  min-width: 42px;
  padding: 50px 0;
  text-align: center;

  &.increased-spacing {
    margin-right: 12px;
  }

  .icon {
    position: absolute;
    cursor: pointer;
  }

  .icon--increment {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: $primaryColor;
    box-shadow: $defaultBoxShadow;
    transition: background-color 0.3s ease-in-out;

    &:before,
    &:after {
      background-color: $uiInnerBackgroundPrimary;
    }

    &.disabled {
      background-color: $lightBorderColor;
    }
  }

  .icon--decrement {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    background-color: #fff;
    border-color: $primaryColor;
    transition: border-color 0.3s ease-in-out;

    &::before {
      background-color: $primaryColor;
      transition: background-color 0.3s ease-in-out;
    }

    &.disabled {
      border-color: $lightBorderColor;

      &::before {
        background-color: $lightBorderColor;
      }
    }
  }

  + .numeric-selection__item {
    margin-left: 5px;
  }
}

.numeric-selection-item__value {
  width: 48px;
  height: 80px;
  @media(min-width: 1200px) {
    width: 58px;
    height: 91px;
  }
  border: 1px solid $thirdColor;
  font-family: $mainFontBold;
  font-size: 32px;
  line-height: 1.8;
  text-align: center;
  transition: border-color 0.2s ease;
  background: linear-gradient(
                  0deg,
                  rgba(167, 167, 167, 0.3) 0%,
                  rgba(255, 255, 255, 0.8) 20%,
                  rgba(255, 255, 255, 0.8) 80%,
                  rgba(167, 167, 167, 0.3) 100%
  );

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
    -moz-appearance: textfield;
  }
}

.numeric-selection-item__value--has-error {
  border-color: $tableNegative;
}

@media (min-width: 768px) {
  .numeric-selection-item__value {
  }
}

@media (min-width: 992px) {
  .numeric-selection-item__value {
    width: 62px;
    font-size: 42px;
  }
}
