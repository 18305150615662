.payment {
  width: 100%;
  margin: 10px 0 28px;
}

@media (min-width: 1200px) {
  .payment {
    padding: 0 30px;
  }
}

.payment__inner {
  @media(min-width: $md) {
    display: grid;
    grid-template-columns: 1fr auto auto auto 1fr;
  }

  text-align: center;
}

.payment__bulk-actions {
  max-width: 140px;
  button {
    width: 100%;

    + button {
      margin-top: 15px;
    }
  }
}

.payment__message {
  margin: 20px 0;
  font-family: $mainFontBold;
  text-transform: uppercase;
  font-size: 17px;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
  color: $primaryColor;
}

.payment__item {
  position: relative;
  display: flex;
  padding-top: 12px;
  flex-grow: 1;
  justify-content: center;
  text-align: center;
  align-items: center;

  &.payment__item--title {
    padding-top: 30px;
  }

  &.payment__item--left-chevron {
    padding-left: 9px;
  }

  &.payment__item--right-chevron {
    padding-right: 9px;
  }

  .dropdown__value {
    width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .payment__title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .chevron {
    margin: 0 10px;
  }

  .payee,
  button {
    align-self: center;
  }
}

.payment__chevron {
  position: absolute;
  right: -9px;

  &:first-child {
    right: auto;
    left: -9px;
  }
}

.payment__title {
  font-size: 21px;
  @media(min-width: 1200px) {
    font-size: 27px;
  }
  font-family: $mainFontBold;
  color: $greenColorPrimary;
  text-transform: uppercase;

  &.payment__title--error {
    color: $redColorPrimary;
  }
}

@media (min-width: 768px) {
  .payment__item {
    .dropdown__value {
      width: 120px;
    }

    + .payment__item {
      margin-top: 0;
    }
  }
}

@media (min-width: 992px) {
  .payment__item {
    .dropdown__value {
      width: 180px;
    }
  }
}
