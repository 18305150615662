/* GRID SYSTEM */
/** ========================================================================== */


@use "sass:math";

$mainBreakPoint: $md;
$gridSize: 12;

/* Grid container */
/* --------------------------------------------------------------------------- */

.container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 950px;
  z-index: 1;
}

.container:before,
.container:after {
  content: "";
  display: table;
  clear: both;
}

.container-fluid {
  display: inline-block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $grid-padding;
  padding-left: $grid-padding;
}

@media(min-width: 768px) {
  .container-fluid {
    padding-right: $grid-padding;
    padding-left: $grid-padding;
  }
}

@media(min-width: 1200px) {
  .container-fluid {
    padding-right: $grid-padding;
    padding-left: $grid-padding;
  }
}

/* GRID HANDLING */
/** ========================================================================== */

.f-grid {
  display: flex;
  margin-right: -$grid-padding;
  margin-left: -$grid-padding;
}

.f-grid-row {
  padding-right: $grid-padding;
  padding-left: $grid-padding;
}

.f-grid-col {
  flex: 0 0 auto;
  flex-grow: 1;
  padding-right: $grid-padding;
  padding-left: $grid-padding;
}

@for $i from 1 through $gridSize {
  .f-grid-col-#{$i} {
    flex: 0 0 math.div($i, $gridSize) * 100%;
    flex-grow: 1;
    padding-right: $grid-padding;
    padding-left: $grid-padding;
    max-width: math.div($i, $gridSize) * 100%;
  }
}

@each $pointName, $inWidth in $breakPoints {
  @media(min-width: $inWidth) {
    .f-grid-#{$pointName} {
      display: flex!important;
      margin-right: -$grid-padding;
      margin-left: -$grid-padding;
    }

    .f-grid-row-#{$pointName} {
      padding-right: $grid-padding;
      padding-left: $grid-padding;
    }

    .f-grid-col-#{$pointName} {
      flex: 0 0 auto;
      flex-grow: 1;
      padding-right: $grid-padding;
      padding-left: $grid-padding;
    }

    @for $i from 1 through $gridSize {
      .f-grid-col-#{$pointName}-#{$i} {
        flex: 0 0 math.div($i, $gridSize) * 100%;
        flex-grow: 1;
        padding-right: $grid-padding;
        padding-left: $grid-padding;
        max-width: math.div($i, $gridSize) * 100%;
      }
    }
  }
}

.clear:before,
.clear:after {
  content: "";
  display: table;
  clear: both;
}
