@use "sass:math";

$checkboxSize: 16px;
$leftAmount: $avatarSize + $checkboxSize + 10;

.wrapingbox {
  padding: 20px 45px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  &.wrapingbox--nocorners {
    padding: 0 0;
    box-shadow: none;
  }
  &.wrapingbox--extendedpadding {
    padding: 35px 60px;
  }
  &.wrapingbox--nowrap {
    white-space: nowrap;
  }
}

.wrapingbox__bottom {
  padding-left: 15px;
  padding-right: 15px;
}

/*.wrapingbox__listing {
    margin-left: -15px;
    margin-right: -15px;
}*/

.wrapingbox__heading {
  margin-bottom: 20px;
  font-family: $mainFontBold;
  font-size: 18px;
  color: $fontColorNormal;
  &.wrapingbox__heading--inline-block {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    width: 50%;
  }

  &.wrapingbox__heading--right {
    text-align: right;
  }
}

.company__single {
  width: percentage(1 * 0.5);
  float: left;
  padding: 10px 15px;
  cursor: pointer;
}

@media (min-width: 576px) {
  .company__single {
    width: percentage(1 * 0.25);
  }
}

@media (min-width: 768px) {
  .company__single {
    width: percentage(math.div(1, 6));
  }
}

@media (min-width: 992px) {
  .company__single {
    width: percentage(1 * 0.125);
  }
}

.company__image {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 12px;
}

.company__midimage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.company__innerimage {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.company__text {
  font-size: 16px;
  font-family: $mainFontRegular;
  text-align: center;
  color: $fontColorNormal;
  word-break: break-word;
}
