.largetime {
  line-height: 1.4;
}

.largetime__status {
  font-family: $mainFontRegular;
  font-size: 16px;
}

.largetime__time {
  font-family: $mainFontLight;
  font-size: 14px;
}
