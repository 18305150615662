// @import '~assets/style/variables.scss';

.main-input {
  padding: 10px 20px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid $primaryColor;

  &.main-input--auto-height {
    height: auto;
  }
}
