.buttons-row {
  display: flex;
  margin-bottom: 20px;
  gap: 7px;

  @media(min-width: $xs) {
    justify-content: flex-end;
    flex-basis: unset;
    gap: 10px;
  }

  @media(max-width: $xs - 1) {
    button {
      flex-basis: 33.3333%;
      font-size: 14px!important;
      padding: 8px 8px!important;
    }
  }
}
