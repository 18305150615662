$bankingHeaderOffset: 64px + $fluidContainerPadding;
$bankingHeaderCompanyOffset: 20px + 10px;

.banking-header {
  margin: -#{$wrapperPadding} -#{$fluidContainerPadding} $fluidContainerPadding;
  background-color: #d1d7da;
}

.banking-header__profile {
  background-color: #849fb6;
  padding: 0 $fluidContainerPadding;
}

.banking-header-profile__inner {
  position: relative;
  padding: $fluidContainerPadding 0;
}

.banking-header-profile__image {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 50%);

  .avatar {
    width: 64px;
    height: 64px;
  }
}

.banking-header-profile__name {
  margin-left: $bankingHeaderOffset + $fluidContainerPadding;
  color: #fff;
  font-family: $mainFontRegular;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.4;
}

.banking-header__transaction {
  margin-top: 25px;
  margin-left: 0;
  @media(min-width: $md) {
    margin-top: 0;
    margin-left: $bankingHeaderOffset;
  }

  @media(max-width: $md - 1) {
    .main-button {
      flex-basis: 50%;
      font-size: 14px;
      padding: 15px 8px!important;
    }
  }

  padding: $fluidContainerPadding;
}

.banking-header__company {
  + .banking-header-transaction__inner {
    margin-top: 15px;
  }
}

.banking-header-transaction__inner {
    @media(max-width: $md - 1) {
      .infoboxes-column__inner {
        margin-right: 10px;
      }

      .infobox__text {
        font-size: 13px;
      }

      .infobox__label {
        font-size: 14px;
      }
    }
}

.banking-header-company__inner {
  position: relative;
}

.banking-header-company__image {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  .avatar {
    width: 20px;
    height: 20px;
  }
}

.banking-header-company__name {
  margin-left: $bankingHeaderCompanyOffset;
  color: $fontColorDark;
  font-family: $mainFontRegular;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
}

.banking-header__code {
  margin-left: $bankingHeaderCompanyOffset;
  color: $subheadingColor;
  font-family: $mainFontRegular;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
}

.transaction__amount {
  &.transaction__amount--positive {
    color: $tablePositive;
  }
  &.transaction__amount--negative {
    color: $tableNegative;
  }
}
