.token-authentication {
  position: relative;
  max-width: 415px;
  margin: 30px auto;
  width: 100%;
  padding: 10px 20px 40px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: $darkBoxShadow;

  &.token-authentication--login {
    max-width: 680px;
  }
}

@media (min-height: 720px) {
  .token-authentication {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }
}

.token-authentication__wrapper {
  width: 50%;
}

.token-authentication__logo {
  height: 120px;
  margin-bottom: 30px;

  img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
}

.token-authentication__text {
  margin-bottom: 10px;
  font-size: 15px;
  font-family: $mainFontRegular;
  text-transform: uppercase;

  &.token-authentication__text--small {
    font-size: 13px;
  }
}

.token-authentication__input {
  margin-bottom: 20px;
}

.token-authentication__footer {
  color: $fontColorGrey;
  font-size: 13px;
  font-family: $mainFontLight;
}

.token-authentication__bottom {
  span {
    font-size: 13px;
    color: $primaryColor;
    font-family: $mainFontRegular;
    cursor: pointer;
  }
}

.token-authentication__sideimage {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
  margin-left: 20px;
  background-color: $primaryColor;
  background-image: url('~assets/img/login-illustration.svg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 576px) {
  .token-authentication__sideimage {
    display: block;
  }
}
