.transactions-details {
  margin-bottom: 30px;
}

.transactions__header {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid $primaryColor;
}

.transactions__heading {
  font-size: 14px;
  font-family: $mainFontLight;
  line-height: 1.4;
  text-transform: uppercase;
}

.transactions__item {
  + .transactions__item {
    margin-top: 15px;
  }
}

.transaction__amount {
  &.transaction__amount--positive {
    color: $tablePositive;
  }
  &.transaction__amount--negative {
    color: $tableNegative;
  }
}
