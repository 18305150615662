.account-balance {
  padding: 10px 35px;
  background-color: $greenColorSecondary;
}

.account-balance__label {
  color: $fontColorLight;
  font-size: 14px;
  line-height: 1.4;
  text-transform: uppercase;
}

.account-balance__value {
  color: $fontColorLight;
  font-size: 30px;
  line-height: 1.4;
  text-transform: uppercase;
}
