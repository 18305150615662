.admin-form {

  .admin-form__area {
    input[type="checkbox"] {
      + span {
        margin-left: 10px;
        width: auto!important;
      }
    }

    span {
      display: inline-block;
      width: 150px;
    }

    select,
    input[type="text"],
    input[type="number"] {
      width: 200px;
      height: 24px;
    }
  }
}

table.admin-table {
  border-collapse: separate;
  border-spacing: 0 1em;

  th {
    padding: .5em;
  }

  th:first-child {
    padding-left: 1em;
  }

  th:last-child {
    padding-right: 1em;
  }

  td {
    padding: .5em;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  td:first-child {
    padding-left: 1em;
    border-left: 1px solid #ccc;
  }

  td:last-child {
    padding-right: 1em;
    border-right: 1px solid #ccc;
  }
}
