.sidebar {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: $mobileBottombarHeight;
  background-color: $uiOverlayBackground;
  z-index: $zIndexSidebar;

  @media (min-width: $mainBreakPoint) {
    top: var(--header-height);
    display: block;
    height: 100%;
    width: $sidebarWidth;
  }

  .sidebar__item {
    display: flex;
    padding: 8px 5px;
    flex: 1 1 0;
    align-items: center;
    justify-content: center;
    transition: all ease 0.2s;
    text-decoration: none;

    &:focus,
    &:hover,
    &.sidebar__item--active {
      background-color: $uiOverlayBackgroundDark;

      .sidebar__label {
        color: #05a7c2;
      }
    }
  }

  .sidebar__link {
    position: relative;
  }

  .sidebar__icon {
    width: 100%;
    height: 28px;
    margin-bottom: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  @each $menuitem in $menuitems {
    .sidebar__icon--#{$menuitem} {
      background-image: url('~assets/img/icons/icon_#{$menuitem}.svg');
    }

    .sidebar__item--active .sidebar__icon--#{$menuitem},
    .sidebar__item:hover .sidebar__icon--#{$menuitem} {
      background-image: url('~assets/img/icons/icon_#{$menuitem}.svg');
    }
  }

  .sidebar__icon--controller {
    height: 23px;
  }

  .sidebar__label {
    text-align: center;
    font-size: 12px;
    font-family: $mainFontRegular;
    color: $fontColorGrey;
    transition: color 0.2s ease;
  }
}
