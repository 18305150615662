.collapse-custom-content {
  padding-left: 10px;
  padding-right: 10px;

  @media(min-width: $md) {
    padding-left: 30px;
    padding-right: 0;
  }

  .collapse-custom-content__times {
    display: grid;
    padding: 0 15px 0 10px;
    gap: 12px 10px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    font-family: $mainFontMedium;

    @media(min-width: $md) {
      margin-bottom: 0;
      padding: 0 80px 0 15px;
    }
  }

}
