.dropdown {
  position: relative;
  display: inline-block;

  .dropdown__menu {
    max-height: 250px;
    overflow: auto;
  }

  .dropdown__button {
    position: relative;
    padding: 16px 22px 16px 42px;
    background-color: $primaryColor;
    color: #fff;
    border: none;
    border-radius: $mainBorderRadius;
    font-size: 16px;
    outline: none;
  }

  .dropdown__icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    @include triangle($position: bottom, $size: 12px, $color: #fff, $topMargin: 5px)
  }

  .dropdown__area {
    position: absolute;
    top: 100%;
    margin-top: 6px;
    right: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid $primaryColor;
    border-radius: $mainBorderRadius;

    @media(max-width: $md - 1) {
      &.dropdown__area--fullscreen {
        position: fixed;
        top: 0;
        bottom: 0;
        margin-top: 0;
        overflow: auto;

        .dropdown__menu {
          max-height: none;
        }

        .dropdown__menu-item {
          padding: 12px 12px;
          font-size: 18px;
        }
      }
    }

    .dropdown__menu-item {
      padding: 8px 12px;
      font-size: 15px;
      cursor: pointer;
    }

  }

}
