/* Global style (body, html, h1, h2, h3, h4 etc) */

:root {
  --header-height: 60px;
}

@media(min-width: $md) {
  :root {
    --header-height: 80px;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}


#app {
  height: 100%;
}

.app-wrapper {
  height: 100%;
  transition: margin-top ease .2s;
  &.app-wrapper--bar-open {
    margin-top: 275px;

    @media(min-width: $md) {
      margin-top: 225px;
    }

  }
}

html {
  height: 100%;
}

body {
  font-family: $mainFontRegular;
  height: 100%;
}

button {
  cursor: pointer;
}

b {
  font-family: $mainFontBold;
}

@media(max-width: $md) {
  table {
    th,
    th > *,
    td,
    td > * {
      user-select: none!important;
    }
  }
}

