.loader-container {
  position: relative;
}

.loader {
  width: 75px;
  height: 75px;
  margin: 0;
  background: transparent;
  border-top: 4px solid $primaryColor;
  border-right: 4px solid transparent;
  border-radius: 50%;
  animation: 1s spin linear infinite;

  &.loader--white {
    border-top-color: #fff;
  }

  &.loader--overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: 1s centered-spin linear infinite;
  }
}

@keyframes centered-spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
