.main-button {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  font-family: $mainFontRegular;
  border: none;
  border-radius: 6px;
  background-color: $primaryColor;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: background-color ease .2s;

  + .main-button--inline {
    margin-left: 15px;
  }

  &:focus,
  &:hover {
    background-color: $primaryColorHover;
  }

  &.main-button--left {
    margin-left: 15px;
  }

  &.main-button--generic {
    padding: 12px 15px;
    font-size: 14px;
    border-radius: 2px;
    text-transform: uppercase;
  }

  &.main-button--secondary {
    background-color: $thirdColor;

    &:focus,
    &:hover {
      background-color: $thirdColorDarker;
    }
  }

  &.main-button--transparent {
    color: $primaryColor;
    background-color: transparent;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &.main-button--inline {
    display: inline-block;
  }

  &.main-button--plain {
    margin-right: 10px;
    background-color: $secondaryColor;
  }

  &.main-button--lg {
    padding: 15px 30px;
  }

  &.main-button--increased-paddings {
    padding-left: 45px;
    padding-right: 45px;
  }

  &.main-button--lg-font {
    font-size: 15px;
  }

  &.main-button--fullwidth {
    width: 100%;
  }

  &.main-button--notice {
    background-color: $noticeColor;
  }

  &.main-button--error {
    background-color: $redColorPrimary;
  }

  &.main-button--green {
    background-color: $greenColorPrimary!important;
    border-color: $greenColorPrimary!important;
    color: #fff;
  }

  &.main-button--orange {
    background-color: $timelineActiveColor!important;
    color: $fontColorNormal;
  }

  &.main-button--disabled {
    @include button-disabled;
  }

  &.main-button--lg {
    padding: 15px 30px;
  }

  &.main-button--hollow {
    border: 1px solid $primaryColor;
    background-color: transparent;
    color: $primaryColor;
    transition: color ease .3s, background-color ease .3s;

    &.main-button--hollow-active {
      background-color: $primaryColor;
      color: #fff;
    }

    &:hover {
      background-color: $primaryColor;
      color: #fff;
    }
  }

  &.main-button--custom-action {
    border-color: $thirdColor!important;
    color: $thirdColor!important;
    transition: color ease .3s, background-color ease .3s;

    &:focus,
    &:hover,
    &:active {
      color: #fff!important;
      background-color: $greenColorLight!important;
      border-color: $greenColorLight!important;
    }

    &.main-button--custom-action-active {
      color: #fff!important;
      background-color: $greenColorPrimary!important;
      border-color: $greenColorPrimary!important;
    }
  }

  &.main-button--lg-fullwidth {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  &.main-button--link-button {
    color: $primaryColor;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &.main-button--add {
    position: relative;
    padding-left: 45px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 15px;
      top: 50%;
      display: inline-block;
      width: 14px;
      height: 2px;
      background-color: #FFFFFF;
    }

    &:before {
      transform: translate(0, -50%);
    }

    &:after {
      transform: translate(0, -50%) rotate(90deg);
    }

    &.active:after {
      content: none;
    }
  }

  &.main-button--absolute-middle-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
}
