.controls {
  display: flex;

  .control__column {
    margin-right: 5px;

    .control__button {
      width: 100%;

      + .control__button {
        margin-top: 5px;
      }
    }

  }
}
