@mixin panelItemStyles() {
  margin-bottom: 15px;
  text-align: center;
  color: $fontColorNormal;
  font-family: $mainFontBold;
  text-decoration: none;
  cursor: pointer;
}

.mobile-sidemenu {

  .mobile-sidemenu__menu {
    position: fixed;
    top: var(--header-height);
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 10px;
    background-color: $wrapperBackground;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    z-index: $zIndexMobileSideMenu;


    .mobile-sidemenu__company-selection,
    .mobile-sidemenu__player-selection {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .mobile-sidemenu__player-selection {
      display: flex;
      flex-wrap: wrap;

      li {
        flex: 1 1 50%;
      }
    }

    .mobile-sidemenu__company,
    .mobile-sidemenu__player, {
      display: flex;
      padding: 6px;
      margin-bottom: 6px;
      align-items: center;
      justify-content: flex-start;
    }

    .mobile-sidemenu__company {
      width: 100%;
      background-color: #fff;
      font-family: $mainFontBold;
      text-transform: uppercase;
      font-size: 14px;
      cursor: pointer;
    }

    .mobile-sidemenu__action-selection {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $fontColorNormal;

      li {

        &,
        a {
          @include panelItemStyles();
          font-family: $mainFontBold;
          text-transform: uppercase;
        }
      }

    }

  }

}
