$innerBorder: 10px;
$outerBorder: $innerBorder * 2;

.sidebar-players {
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: $mobileBottombarHeight;
  left: auto;
  width: $playersSidebarWidth;
  overflow-y: auto;
  background-color: $uiInnerBackgroundPrimary;
  box-shadow: $defaultBoxShadow;
  z-index: $zIndexPlayersBar;

  @media(min-width: $md) {
    bottom: 0;
  }
}

.sidebar-players__list {
  padding: 20px 0;
}

.sidebar-players__item {
  transition: all 0.4s ease;
  cursor: pointer;

  + .sidebar-players__item {
    margin-top: 15px;
  }

  + .sidebar-players__item--active {
    margin-top: 20px;
  }

  .sidebar-players__icon {
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      transition: all 0.8s ease;
    }

    .icon {
      position: absolute;
      top: -15px;
      left: -15px;
    }
  }

  .avatar {
    position: relative;
    z-index: 1;
  }
}

.sidebar-players__item--active {
  .sidebar-players__icon {
    + .sidebar-players-item__name {
      margin-top: 10px;
    }

    &:after {
      width: $innerBorder + $avatarSize;
      height: $innerBorder + $avatarSize;
      background-color: $greenColorPrimary;
    }

    &:before {
      width: $outerBorder + $avatarSize;
      height: $outerBorder + $avatarSize;
      background-color: $greenColorLight;
    }
  }
}

.sidebar-players-item__name {
  padding: 0 10px;
  color: $greenColorPrimary;
  font-family: $mainFontRegular;
  font-size: 12px;
  line-height: 1.4;
}
