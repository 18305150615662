@mixin setUnderlineColor($color) {
  &:after {
    background-color: $color;
  }
}

.underline-colored-label {
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 6px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 30px;
    width: 100%;
    height: 4px;
  }

  &.underline-colored-label--green {@include setUnderlineColor($greenColorPrimary);}
  &.underline-colored-label--red {@include setUnderlineColor($redColorPrimary);}
  &.underline-colored-label--timeline-color {@include setUnderlineColor($timelineActiveColor);}
  &.underline-colored-label--primary-color {@include setUnderlineColor($primaryColor);}

}
