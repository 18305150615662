.staging-controls {
  position: relative;
  padding: 15px 15px 40px;

  &.staging-controls--warning {
    background-color: $redColorPrimary;

    > * {
      color: #fff;
    }

    .timeline__final-point {
      &:before {
        background-color: #fff;
      }
    }

    .control-button__red {
      border: 1px solid #fff;
    }
  }

  .staging-controls__locked-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 24px 32px;
    background-color: $redColorPrimary;
    z-index: 1;
  }

  .staging-controls__inner {
    height: 100%;
    align-items: center;
  }

  .staging-controls__label {
    color: #fff;
    font-family: $mainFontBold;
    font-size: 27px;

    &.staging-controls__label--sm {
      font-size: 16px;
    }
  }

}
