.lang-switch {
  list-style-type: none;
  padding-left: 0;

  .lang-switch__item {
    text-transform: uppercase;
    font-family: $mainFontBold;
    font-size: 16px;
    text-decoration: none;
    color: $fontColorNormal;
    display: inline-block;
    background-color: transparent;
    border: none;

    @media(min-width: $md) {
      font-size: 14px;
    }

    &:focus,
    &:hover,
    &:active {
      border: none;
      text-decoration: none;
      outline: none;
    }

    &.lang-switch__item--active {
      color: $primaryColor;
    }

  }

}
