.pagination {

  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: $md - 1) {
    gap: 10px;
    flex-direction: column;
    align-items: baseline;
  }

  .pagination__pages {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination__dropdown {
    display: flex;
    margin-right: 60px;
    align-items: center;
    justify-content: center;

    .pagination__dropdown-label {
      margin-right: 12px;
      font-family: $mainFontBold;
    }
  }

  .pagination__pages-label {
    padding: 0 60px;
    font-family: $mainFontBold;
  }

  .pagination__arrow {
    display: inline-block;
    padding: 9px 22px;
    border-radius: $mainBorderRadius;
    background-color: $primaryColor;
    cursor: pointer;

    .pagination__icon {
      height: 24px;
      width: 12px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("~assets/img/chevron.svg?fill=#ffffff");
    }

    &:last-child {
      .pagination__icon {
        transform: rotate(180deg);
      }
    }
  }
}
