.switch {
  position: fixed;
  bottom: 20px;
  right: $sidebarWidth * 0.5;
  transform: translateX(50%);
  z-index: $zIndexFacilitatorDesktopSwitchButton;

  @media(max-width: $md - 1) {
    display: none;
  }

  .switch__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background-color: $primaryColor;
    z-index: 1;
    transition: background-color ease .3s;

    &:focus,
    &:hover,
    &:active,
    &.switch__icon--active {
      background-color: $primaryColorDark;
    }

    .icon {
      width: 23px;
      height: 20px;
      background-size: contain;
      background-position: center center;
    }

  }

  .switch__modal {
    position: absolute;
    right: $sidebarWidth - 30;
    bottom: 0;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 0 25px -12px rgba(0,0,0,0.75);
    border-radius: 10px;
  }

  .switch__list {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    .switch__item {
      display: flex;
      margin-bottom: 10px;
      gap: 10px;
      align-items: center;
      font-family: $mainFontBold;
      text-transform: uppercase;
      font-size: 19px;
      cursor: pointer;
      transition: color ease .3s;

      &.switch__item--border {
        padding-top: 10px;
        border-top: 1px solid $fontColorDark;
      }

      &:focus,
      &:hover,
      &:active {
        color: $primaryColor;
      }

    }

  }

}
