$verticalPadding: 12px;
$horizontalPadding: 10px;
$mobTablePadding: $verticalPadding $horizontalPadding;

.mob-collapse-table {

  .mob-collapse-table__item {
    border-bottom: 2px solid transparent;
    padding: $verticalPadding + 6 $horizontalPadding;
    @media(min-width: $sm) {
      padding: $mobTablePadding;
    }
    background-color: #fff;
    cursor: pointer;

    + .mob-collapse-table__item {
      margin-top: 8px;
    }

    &.mob-collapse-table__item--active {
      border-bottom-color: $primaryColor;
    }
  }

  .mob-collapse-table__child-wrapper {
    position: relative;
    padding: $mobTablePadding;

    + .mob-collapse-table__child-wrapper {

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: $horizontalPadding;
        left: $horizontalPadding;
        height: 1px;
        background-color: $fontColorNormal;
      }

    }
  }

  .mob-collapse-table__children {
    margin-bottom: 10px;
    border-spacing: 0;
    width: 100%;

    &.mob-collapse-table__children--transparent {
      td {
        background-color: transparent!important;
      }
    }

    th {
      padding: 5px;
      @media(min-width: $xs) {
        padding: 10px;
      }
      text-align: left;
    }

    td {
      padding: 12px 5px;
      @media(min-width: $xs) {
        padding: 10px;
      }
      border-bottom: 3px solid #F6F6F6;
      background-color: #fff;
      border-bottom: 2px solid $wrapperBackground;
    }
  }

  .mob-collapse-table__turncated-content {
    @media(max-width: $md - 1) {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
    }
  }

  @media(max-width: $sm - 1) {
    .avatar {
      height: 30px;
      width: 30px;
    }
  }
}
