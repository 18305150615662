.mid-info__item {
  font-size: 16px;
  margin-bottom: 20px;
  font-family: $mainFontRegular;
  color: $fontColorNormal;
}

.mid-info__bold {
  margin-bottom: 4px;
  font-size: 18px;
  font-family: $mainFontRegular;
  color: $fontColorDark;
}
