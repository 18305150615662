.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background-color: $uiOverlayBackground;
  padding-bottom: 6px;
  padding-top: 11px;
  z-index: $zIndexHeader;

  @media(min-width: $md) {
    padding-bottom: 10px;
    padding-top: 15px;
    padding-left: $sidebarWidth;
  }

}

.header__game-state {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  @media(min-width: $md) {
    right: $playersSidebarWidth;
    left: $sidebarWidth;
  }
  background-color: #fff;
}

.header__center-wrapper {
  margin-left: $sidebarWidth;
}

.header__gameinfo {
  margin-bottom: 5px;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $fontColorLight;
}

.header__companytitle {
  text-transform: uppercase;
  font-size: 12px;
  @media(min-width: $md) {
    font-size: 17px;
  }
}

.header__side {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: $sidebarWidth;
  background-color: $headerEdgeBackground;

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
  }
}

.header__cell {
  display: table-cell;
  vertical-align: middle;

  &.header__cell--textright {
    text-align: right;
  }
}

.headerutils {
  white-space: nowrap;
}

.headerutils__item {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  @media(max-width: $md - 1) {
    margin: 0 6px;
  }

  @media(min-width: $md) {
    + .headerutils__item {
      margin-left: 20px;
      padding-left: 15px;
    }
  }

}
