@import '~assets/style/variables.scss';

.avatar {
  position: relative;
  width: $avatarSize;
  height: $avatarSize;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 100%;
  z-index: 1;
  &.avatar--absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.avatar--lg {
    width: 100px;
    height: 100px;
  }
  &.avatar--sm {
    width: 20px;
    height: 20px;
  }
  &.avatar--md {
    width: $avatarSize;
    height: $avatarSize;
  }
  &.avatar--smallbg {
    .avatar__inner {
      background-size: 50%;
      background-color: $greenColorLight;
    }
  }
  &.avatar--clickable {
    cursor: pointer;
  }

  &.green-borders {
    padding: 10px;
    .avatar__inner {
      position: relative;
      border-radius: 50%;
      z-index: 2;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
    }

    &::after {
      top: 5px;
      right: 5px;
      left: 5px;
      bottom: 5px;
      background-color: $greenColorPrimary;
      border-radius: 50%;
      z-index: 1;
    }

    &::before {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: $greenColorLight;
      border-radius: 50%;
      z-index: 0;
    }
  }
}

.avatar__inner {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $secondaryColor;
}

.avatar__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $avatarSize;
  height: $avatarSize;
  margin-left: -20px;
  margin-top: -20px;
}
