.timeline {
  position: relative;
  width: 100%;
  min-width: 150px;
  height: 4px;
  border-radius: 5px;
}

.timeline__single {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: $fontColorLight;
  box-shadow: $defaultBoxShadow;

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    &:before {
      content: '';
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    .timeline__point {
      display: none;
    }

    &:before {
      content: '';
      right: 0;
      transform: translate(50%, -50%);
    }
  }
  &.timeline__single--finished {
    background-color: $timelineActiveColor;
    .timeline__point,
    &:before {
      border-color: $timelineActiveColor;
    }
  }

  &:before {
    position: absolute;
    top: 50%;
    width: $timelinePointSize;
    height: $timelinePointSize;
    border: 1px solid $lightBorderColor;
    border-radius: 100%;
    background-color: $fontColorLight;
    z-index: 1;
  }
}

.timeline__point {
  position: absolute;
  right: 0;
  top: 50%;
  width: $timelinePointSize;
  height: $timelinePointSize;
  border: 1px solid $lightBorderColor;
  border-radius: 100%;
  transform: translate(50%, -50%);
  background-color: $fontColorLight;
  z-index: 1;
}

.timeline__progress {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-color: $timelineActiveColor;
}
