.infobox {
  height: 100%;
  flex-grow: 1;

  + .main-table {
    margin-top: 30px;
  }
}

.progressboard {
  + .infobox {
    margin-top: 15px;
  }
}

.infobox__wrapper {
  position: relative;
  height: 100%;
  padding: 20px 8px;
  box-shadow: $defaultBoxShadow;
  background-color: #fff;
  @media(min-width: $md) {
    padding: 20px;
  }

  &.infobox__wrapper--transparent {
    box-shadow: none;
    background-color: transparent;
  }
}

.infobox__wrapper--sm {
  padding: 10px;
}

.infobox__wrapper--no-padding {
  padding: 0;
}

.infobox-column__inner {
  position: relative;

  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 36px;
    height: 36px;
  }
}

.infobox__column--lg {
  .infobox__label {
    font-size: 16px;
  }
  .infobox__text {
    font-size: 34px;
  }
}
.infobox__column--primary {
  .infobox__label {
    position: relative;
    padding-bottom: 5px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 30px;
      width: 100%;
      height: 4px;
      background-color: $timelineActiveColor;
    }
  }
}
.infobox__column--border-green {
  .infobox__label:after {
    background-color: $greenColorSecondary;
  }
}

.infobox--grouped {
  .infobox__inner {
    background-color: $fontColorLight;
  }

  .infobox__wrapper {
    box-shadow: none;
  }

  .infobox__grid {
    position: relative;

    + .infobox__grid {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        display: inline-block;
        width: 1px;
        height: 40px;
        background-color: $lightBorderColor;
      }
    }
  }
}

.infobox__innersingle {
  position: relative;
  display: block;
  width: auto;
  padding: 0 20px;

  + .infobox__innersingle {
    padding-top: 10px;
    margin-top: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: 0;
      background-color: $fontColorDark;
      width: auto;
      height: 1px;
    }
  }
}

@media (min-width: 768px) {
  .infobox__innersingle {
    display: inline-block;
    width: 33.3333%;
    padding: 0 20px;

    + .infobox__innersingle {
      padding-top: 0;
      margin-top: 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 0;
        background-color: $fontColorDark;
        width: 1px;
        height: auto;
      }
    }
  }
}

.infobox__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.infobox__header--with-icon {
  position: relative;
  padding-left: 60px;
}

.infobox__header--underlined {
  padding-bottom: 5px;
  border-bottom: 2px solid $primaryColor;
}

.infobox__icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 45px;
  height: 45px;
}

.infobox__heading {
  font-family: $mainFontRegular;
  color: $fontColorDark;
  font-size: 18px;
  line-height: 1.4;
}

.infobox-heading__highlight {
  position: relative;
  padding: 0 30px 0 15px;
  background-color: $spotlightColor;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background: -moz-linear-gradient(
                    110deg,
                    rgba(255, 255, 255, 0) 50%,
                    rgba(255, 255, 255, 1) 55%
    );
    background: -webkit-linear-gradient(
                    110deg,
                    rgba(255, 255, 255, 0) 50%,
                    rgba(255, 255, 255, 1) 55%
    );
    background: linear-gradient(
                    110deg,
                    rgba(255, 255, 255, 0) 50%,
                    rgba(255, 255, 255, 1) 55%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
  }
}

.infobox__wrapper--primary {
  .infobox__heading {
    position: relative;
    padding-bottom: 5px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 60px;
      width: 100%;
      height: 4px;
      background-color: $timelineActiveColor;
    }
  }
}
.infobox__wrapper--no-background {
  background-color: transparent;
  box-shadow: none;
}

.infobox__subheading {
  color: $fontColorGrey;
  font-size: 16px;
  font-family: $mainFontLight;
  line-height: 1.4;
}

.infobox__label {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  color: $fontColorDark;
}

.infobox__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    width: 45px;
    height: 45px;
  }
}

.infobox__text {
  font-size: 24px;
  font-family: $mainFontRegular;
  color: $fontColorDark;

  @media(min-width: 1200px) {
    font-size: 32px;
  }
}
