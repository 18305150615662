.user-select-list {

  .user-select-list__list {
    max-width: 850px;
    margin: 0 auto;
    column-count: 2;
    column-gap: 10px;
    @media(min-width: $md) {
      column-count: 3;
      column-gap: 40px;
    }
  }

  .user-select-list__header {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    @media(min-width: $md) {
      flex-wrap: nowrap;
      margin-bottom: 46px;
    }
  }

  input {
    height: 46px;
    width: 385px;
    padding: 0 20px;
    border: 1px solid $primaryColor;
  }

}
