.timer {
  display: flex;
  align-items: center;
  color: #fff;

  .timer__time-left {
    margin-bottom: 5px;
    font-size: 16px;
    @media(min-width: $md) {
      font-size: 26px;
    }
  }

  .timer__label {
    margin-right: 20px;
  }

  .timer__stage-display {
    font-size: 12px;
    @media(min-width: $md) {
      font-size: 16px;
    }

    strong {
      text-transform: uppercase;
    }
  }

  &.timer--inline-block {
    display: inline-block;
  }

  &.timer--sm {
    margin-right: 0;

    .timer__icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }

  .timer__notification {
    background-color: $greenColorPrimary;
  }

  &.timer--locked {
    .timer__notification {
      background-color: $redColorPrimary;
    }
  }

}


.timer__icon {
  position: relative;
  top: 2px;
  left: 2px;
  display: inline-block;
  width: $timerSize;
  height: $timerSize;
  margin: 10px 10px 0 0;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('~assets/img/icons/icon_clock.svg?fill=#{$greenColorPrimary}');

  .timer__notification {
    position: absolute;
    right: -12px;
    top: -13px;
    height: 16px;
    width: 16px;
    border-radius: 100%;
  }

  .icon {
    height: 9px;
    width: 8px;
    background-size: cover;
    left: 4px;
    top: -3px;
    &.icon--play:before {
      border-left-color: $uiOverlayBackground;
    }
  }

}

.timer__wrapper {
  position: relative;
  top: 2px;
  display: inline-block;
  font-size: 12px;
}
