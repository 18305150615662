.filterbuttons {
  display: flex;
  width: 100%;
  margin: 20px auto 20px;
  @media (min-width: 850px) {
    width: 70%;
  }
}

.filterbuttons__single {
  flex: 1;
  padding: 12px 8px;
  cursor: pointer;
  color: $fontColorDark;
  text-align: center;
  border: 1px solid $primaryColor;
  transition: all ease 0.2s;
  line-height: 1.4;
  outline: none;
  text-transform: uppercase;
  background-color: #fff;
  font-size: 13px;

  @media(min-width: $md) {
    padding: 15px;
    font-size: 16px;
  }

  &:focus,
  &:hover,
  &.filterbuttons__single--active {
    color: #fff;
    background-color: $primaryColor;
  }

  &:first-child {
    border-right-color: transparent;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-left-color: transparent;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
