.custom-content-area {
  padding: 20px 15px;
  background-color: #fff;
  border: 1px solid $timelineActiveColor;

  a {
    text-decoration: none;
    color: $primaryColor;
    border-bottom: 1px solid;
    border-bottom-color: transparent;
    transition: border-bottom-color ease .3s;

    &:focus,
    &:hover {
      border-bottom-color: $primaryColor;
    }
  }
}
