.topheader {
  margin-bottom: 15px;

  &.topheader--row {
    display: flex;
    gap: 15px;
  }

}

.topheader__primary {
  font-size: 24px;
  font-family: $mainFontRegular;
  color: $fontColorDark;
  &.topheader__primary--spacing {
    margin-bottom: 30px;
  }

  + .topheader__secondary {
    margin-top: 5px;
  }
}
.topheader__secondary {
  font-size: 16px;
  font-family: $mainFontRegular;
  color: $fontColorGrey;
}

.topheader__interact {
  text-align: right;
}
