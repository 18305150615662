.locked-overlay {
  height: 130px;
  padding: 24px 32px;
  background-color: $redColorPrimary;
  z-index: 1;
}

.locked-overlay__inner {
  height: 100%;
  align-items: center;
}

.locked-overlay__label {
  color: #fff;
  font-family: $mainFontBold;
  font-size: 27px;

  &.locked-overlay__label--sm {
    font-size: 16px;
  }
}
