@use "sass:math";

$cardViewHeight: math.div($cardViewWidth, 2.3);
$cardViewAvatarWidth: $cardViewWidth * 0.3;

.cardview {
  text-align: center;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
}

.cardview__single {
  display: inline-block;
}

.cardview__item {
  position: relative;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  padding: 15px 15px 30px 15px;
  border: 1px solid $lightBorderColor;
  width: $cardViewWidthSmall;
  @media(min-width: 1200px) {
    width: $cardViewWidth;
  }
  height: $cardViewHeight;
  &.cardview__item--clickable {
    cursor: pointer;
  }
  &.cardview__item--selected {
    border-color: $subTablePositive;
    &:before {
      background-image: url("~assets/img/icons/icon_check_green_fill.svg");
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    background-image: url("~assets/img/icons/icon_check_gray.svg");
    z-index: 1;
  }
  &:after {
    background-image: none;
    background-color: #fff;
    z-index: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.cardview__inner {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid $fontColorGrey;
  padding: 10px ($cardViewAvatarWidth + 15px) 15px 15px;
  text-align: left;
}

.cardview__avatar {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: $cardViewAvatarWidth;
  background-color: darkolivegreen;
}

.cardview__content {

}

.cardview__title {
  font-family: $mainFontRegular;
  font-size: 18px;
  color: $fontColorNormal;
}

.cardview__desc {

}
