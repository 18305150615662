.task-indicator {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-family: $mainFontMedium;

  @media(min-width: $md) {
    width: 180px;
    padding: 4px 15px;
    font-size: 16px;
  }

  &.skip {
    background-color: $redColorPrimary;
  }

  &.progress {
    background-color: $timelineActiveColor;
  }

  &.done {
    background-color: $greenColorPrimary;
  }
}
