.collapse-table {
  .avatar {
    display: inline-block;
  }

  &.collapse-table--side-paddings {
    .collapse-table__row td,
    td {
      padding: 0 .5em;

      &:first-child {
        padding-left: 1em;
      }
      &:last-child {
        padding-right: 1em;
      }

      @media(min-width: $md) {
        &:first-child {
          padding-left: 2em;
        }
        &:last-child {
          padding-right: 2em;
        }
      }
    }
  }

  &.collapse-table--last-icons {
    th,
    td {
      &:last-child {
        width: 2em;
      }
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
  }

  .collapse-table__group-title {
    th {
      padding: 15px 0;
      background-color: #fff;
      border-bottom: 5px solid $wrapperBackground;
      &:first-child,
      &:last-child {
        padding: 15px 10px;
        @media(min-width: $md) {
          padding: 15px 40px;
        }
      }
    }

    &.extended {
      th {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $primaryColor;
          height: 2px;
        }
      }

      .chevron {
        transform: rotate(90deg);
      }
    }

    &.collapse-table__group-title--main {
      th {
        border-bottom: 2px solid $primaryColor;
      }

      + .collapse-table__group-title {
        th {
          border-top: 5px solid $wrapperBackground;
        }
      }
    }

  }

  .extended {
    .collapse-table__cell-data {
      padding: 4px 0;
      height: auto;
      overflow: visible;
    }

    .collapse-table__inner-head {
      .collapse-table__cell-data {
        padding: 8px 0;
        font-family: $mainFontBold;
      }
    }
  }

  .collapse-table__inner-head {
    td {
      padding: 0;
    }
  }

  .collapse-table__cell-data {
    height: 0;
    padding: 0;
    overflow: hidden;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .collapse-table__row {
    background-color: #fff;

    td {
      padding: 0;
    }
  }

  .collapse-table__inner-area {
    &.extended {
      display: table-row-group;

      .collapse-table__row {
        td {
          border-bottom: 5px solid $wrapperBackground;
        }

        &:last-child {
          td {
            border-bottom: 15px solid $wrapperBackground;
          }
        }
      }
    }
  }

  .chevron {
    transform: rotate(180deg);
    height: 20px;
    width: 10px;
  }
}
