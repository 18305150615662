/* SCSS Variables */

/* General comp styles */

$avatarSize: 46px;
$avatarHeader: $avatarSize + 10px;
$timerSize: 35px;
$sidebarWidth: 80px;
$playersSidebarWidth: 80px;
$wrapperPadding: 25px;
$fluidContainerPadding: 15px;
$timelinePointSize: 12px;
$datepickerWidth: 300px;
$cardViewWidth: 400px;
$cardViewWidthSmall: 300px;

/* Box shadows */

$boxShadowColor: rgb(220, 216, 220);
$boxShadowRGBALight: rgba(220, 216, 220, 1);
$boxShadowRGBAPrimary: rgba(5, 167, 194, 1);
/* Colors */

$primaryColor: #03A9F4;
$secondaryColor: #292C33;
$thirdColor: #829595;
$thirdColorDarker: #556161;
$fontColorLight: #FFFFFF;
$subheadingColor: #666666;
$fontColorDark: #010101;
$fontColorNormal: #3E474C;
$fontColorGrey: #999999;
$lightBorderColor: #CBCBCB;
$greenColorPrimary: #66CC33;
$greenColorSecondary: #99CC99;
$greenColorLight: #BDEAB2;

$placeholderColor: #959595;



$redColorPrimary: #AB1919;
$groupBorderColor: #E1E1E1;

$fieldsLabelColor: #666666;
$fieldsInputColor: $fontColorDark;
$fieldsInputBorderColor: #959595;
$disabledFontColor: $fontColorDark;
$disabledBackgroundColor: $fontColorGrey;

$defaultIconsColor: #9B9792;

$timelineActiveColor: #FF9900;

$headerEdgeBackground: #FFCB28; //selected with colorpicker, check later!
$uiOverlayBackground: #374A4F; //selected with colorpicker, check later!
$wrapperBackground: #F6F6F6;
$wrapperHeaderBackground: #C5CDD0;
$uiInnerBackgroundPrimary: #FFFFFF;

$noticeColor: $greenColorPrimary; //selected with colorpicker, check later!
$noticeColorDark: #4d7723; //selected with colorpicker, check later!
$noticeColorDarker: #355218; //selected with colorpicker, check later!
$primaryColorDiff: #00CCCC; //selected with colorpicker, check later!
$primaryColorDark: #009999; //selected with colorpicker, check later!
$spotlightColor: #FFCC00;
$spotlightColorDark: #DD9F03;
$spotlightColorLight: #FDF1C9;
$customLineColor: #D6D6D6;
$primaryColorHover: #167888;
$tableNegative: #FF6666;
$tablePositive: #33CC33;
$subTableNegative: #FF9999;
$subTablePositive: #8DC63F;

$tableExtendedRowColor: #efefef;
/* fonts */

$mainFontRegular: 'Roboto_regular';
$mainFontItalic: 'Roboto_italic';
$mainFontBold: 'Roboto_bold';
$mainFontLight: 'Roboto_light';
$mainFontMedium: 'Roboto_medium';

$menuitems: 'building', 'stacks', 'money', 'company_money', 'list', 'user', 'controller', 'people';

$icons:
    'account_colored',
    'building',
    'case',
    'check',
    'skip',
    'clock',
    'company_money',
    'hand',
    'list',
    'money',
    'payment',
    'stacks',
    'task_colored',
    'task_personal',
    'task_company',
    'user',
    'person',
    'game_end',
    'game_lock',
    'game_unlock',
    'game_start',
    'controller',
    'people',
    'zoom'
;

$defaultBoxShadow: 0px 0px 5px 0px $boxShadowColor;
$darkBoxShadow: 0 16px 34px 0 rgba(51,51,51,0.4);

$balanceInputsWidth: 342px;
$grid-padding: 8px;
