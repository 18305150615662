.stopper {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}

.stopper__label {
  margin-top: 10px;
  font-family: $mainFontRegular;
  font-size: 18px;
  color: $primaryColor;
}

.stopper__button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
  border: 1px solid $primaryColor;
  border-radius: 100%;
  cursor: pointer;
  &.stopper__button--large {
    width: 120px;
    height: 120px;
    margin: 0 40px;
  }
  &.stopper__button--next {
    .stopper__icon {
      &:before {
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-left: 5px;
        @include triangle($position: right, $size: 10px, $color: $primaryColor);
      }
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 24px;
        top: 7px;
        right: 10px;
        background-color: $primaryColor;
      }
    }
  }
  &.stopper__button--pause {
    .stopper__icon {
      &:before {
        content: '';
        left: 50%;
        top: 50%;
        margin-left: 12px;
        transform: translate(-50%, -50%);
        @include triangle($position: right, $size: 14px, $color: $primaryColor);
      }
    }
  }
  &.stopper__button--stop {
    .stopper__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      width: 20px;
      background-color: $primaryColor;
    }
  }
  &.stopper__button--continue {
    .stopper__icon {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 40px;
        background-color: $wrapperBackground;
        margin-left: -20px;
        border-left: 15px solid $primaryColor;
        border-right: 15px solid $primaryColor;
      }
    }
  }
}

.stopper__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%);
  &:before,
  &:after {
    position: absolute;
  }
}

.stopper__external {
  display: inline-block;
  position: relative;
}
