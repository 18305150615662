.main-title {
  margin-bottom: 25px;
  font-size: 27px;
  text-align: center;
  color: $primaryColor;
  text-transform: uppercase;
  font-family: $mainFontBold;
}

.wrapper {
  background-color: $wrapperBackground;
  margin-top: var(--header-height);
  padding-top: $wrapperPadding;
  padding-bottom: 150px;
  min-height: 100%;

  @media(min-width: $md) {
    margin-left: $sidebarWidth;
    padding-bottom: 80px;
  }

  &.wrapper--alternate {
    margin-top: 0;
    margin-left: 0;
    padding-top: 0;
  }

  &.wrapper--main-bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $primaryColor;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primaryColor;
      opacity: 0.3;
    }
  }

  &.wrapper--offset-top {
    margin-top: 70px;
  }

  &.wrapper--no-padding-top {
    padding-top: 0;
  }

  &.wrapper--no-sidebar {
    margin-left: 0;
    padding-bottom: 50px;

    .lightbox {
      left: 0;
    }
  }
}

.wrapper__header {
  padding: 20px 0;

  &.wrapper__header--overflow {
    background-color: $wrapperHeaderBackground;
    padding-bottom: 80px;
    margin-bottom: -60px;
  }
}

.wrapper__bgelement {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background-color: $secondaryColor;
}

.marginal-spacing {
  margin-bottom: 15px;
}

/* HEADINGS */

.primary-heading {
  color: $fontColorDark;
  font-size: 16px;
  font-family: $mainFontBold;
}

.content__header {
  margin-bottom: 15px;
}

/* INPUTS */

.basic-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid $fieldsInputBorderColor;
  font-size: 15px;
  color: $placeholderColor;
  outline: none;
  &.basic-input--textarea {
    padding: 10px;
    border: 1px solid $fieldsInputBorderColor;
    resize: none;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholderColor;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $placeholderColor;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $placeholderColor;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $placeholderColor;
  }
}

.general-error {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px 10px;
  font-family: $mainFontMedium;
  color: #fff;
  background-color: $redColorPrimary;
  border-radius: 2px;
}

.customtemplatearea {
  position: relative;
}

.customtemplatearea__card {
  @media(min-width: 900px) {
    position: absolute;
    left: 0;
    top: 0;
    width: $cardViewWidth;
    .cardview__item {
      margin: 0;
    }
  }
}

.customtemplatearea__form {
  @media(min-width: 900px) {
    padding-left: $cardViewWidth + 50px;
  }
}


.form-control__group {
  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
}

.form-control__full {
  width: 100%;
  padding: 0 15px;
}

.form-control__halved {
  padding: 0 15px;
  width: 50%;
  float: left;
}

.form-control__label {
  display: block;
  margin-bottom: 5px;
  color: $primaryColor;
  font-size: 12px;
  line-height: 1.2;
  font-family: $mainFontRegular;
}

.form-control__buttons {
  text-align: right;
}

.form-control__notification {
  background-color: $tableNegative;
  padding: 8px 10px;
  margin: 20px 0;
  font-family: $mainFontMedium;
  font-size: 15px;
  color: $fontColorNormal;
}

.checkbox {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.row-clickable--active {
  .checkbox__icon {
    &:before {
      content: "";
    }
  }
}

.checkbox__icon {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid $primaryColor;
  border-radius: 4px;
  &:before {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-left: -4px;
    margin-top: -4px;
    background-color: $primaryColor;
  }
}

.checkbox__label {
  display: inline-block;
}

/* Some text styles */

.customtext {
  font-size: 12px;
  &.customtext--lg {
    font-size: 14px;
    line-height: 1.4;
  }
}

.general-box {
  padding: 20px;
  box-shadow: $defaultBoxShadow;
  background-color: #fff;
}

.general-box__limitation {
  max-width: 900px;
}

.main-heading {
  margin-bottom: 20px;
  color: $fontColorNormal;
  font-size: 21px;
  font-family: $mainFontRegular;
}

.icon {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.icon--increment,
.icon--decrement {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 42px;
  border-radius: 6px;
  border: 1px solid $lightBorderColor;

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 17px;
    height: 3px;
    border-radius: 4px;
    background-color: $defaultIconsColor;
  }

  &:before {
    content: "";
    transform: translate(-50%, -50%);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.icon--close {
  border: 1px solid $defaultIconsColor;
  border-radius: 50%;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 10px;
    background-color: $defaultIconsColor;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &.style--red {
    border-color: $redColorPrimary;

    &::before, &::after {
      background-color: $redColorPrimary;
    }
  }
}

.icon--increment {
  &:after {
    content: "";
  }
}

.icon--play {
  background-color: $greenColorPrimary;
  border-radius: 50%;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 6px solid $fontColorLight;
    transform: translate(-20%, -50%);
  }

  &.icon--lg {
    width: 46px;
    height: 46px;

    &:before {
      border-top: 8px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 16px solid $fontColorLight;
    }
  }
}

.icon--lock {
  background-color: $redColorPrimary;
  border-radius: 50%;

  background-image: url("~assets/img/icons/icon_game_lock.svg?fill=#FFFFFF");
}

.icon--game_start {
  width: 20px;
  height: 22px;

  &.icon--game_start-lg {
    width: 42px;
    height: 48px;
  }
}

.icon--eye {
  width: 23px;
  height: 19px;
}

.icon--zoom {
  width: 20px;
  height: 20px;
}

.icon--game_unlock,
.icon--game_lock {
  background-repeat: no-repeat;
  background-size: cover;
}

.icon--game_unlock {
  height: 21px;
  width: 15px;
}

.icon--game_lock {
  height: 21px;
  width: 18px;
}

.icon--game_end {
  height: 17.3px;
  width: 17.3px;
}

.icon--img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

@each $icon in $icons {
  .icon--#{$icon} {
    background-image: url("~assets/img/icons/icon_#{$icon}.svg");

    &.icon--fill-white {
      background-image: url("~assets/img/icons/icon_#{$icon}.svg?fill=#FFFFFF");
    }

    &.icon--fill-grey {
      background-image: url("~assets/img/icons/icon_#{$icon}.svg?fill=#{$groupBorderColor}");
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.justify-content-center {
  justify-content: center!important;
}

.justify-content-flex-end {
  justify-content: flex-end!important;
}

.response {
  display: none;
  padding: 5px 15px;
  background: #66CC33;
  color: #FFF;
}

.response--visible {
  display: inline-block;
}

.chevron {
  display: inline-block;
  height: 35px;
  width: 18px;
  background-size: cover;
  background-image: url("~assets/img/chevron_lg.svg");

  &.chevron--sm {
    height: 20px;
    width: 9px;
    background-image: url("~assets/img/chevron_sm.svg");
  }
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.color-primary {
  color: $primaryColor;
}

.color-font-dark {
  color: $fontColorDark;
}

.red-color-primary {
  color: $redColorPrimary;
}

.bold {
  font-family: $mainFontBold;
}

.color-third {
  color: $thirdColor;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.underlined-subtitle {
  position: relative;
  display: inline-block;
  font-size: 21px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $timelineActiveColor;
  }
}

.bg-transparent {
  background-color: transparent!important;
}

.mob-player-action-button {
  position: fixed;
  bottom: $mobileBottombarHeight + 10;
  right: 10px;
  z-index: $zIndexPlayersBar - 1;

  @media(min-width: $md) {
    display: none!important;
  }

  &.mob-player-action-button--to-bottom {
    bottom: 10px;
  }

}

$burgerSize: 40px;
$burgerLineSpaces: 8px;

.burger {
  display: flex;
  width: $burgerSize;
  height: $burgerSize;
  flex-direction: row;
  align-items: center;

  .burger__lines {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #fff;

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      height: 1px;
      background-color: #fff;
    }

    &:before {
      top: -$burgerLineSpaces;
      width: 70%;
    }

    &:after {
      top: $burgerLineSpaces;
      width: 40%;
    }

  }

}

