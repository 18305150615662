.rw-widget-picker.rw-widget-container {
  border: none;
  border-bottom: 1px solid #cbcbcb;
}

.basic-input-element {
  margin-bottom: 30px;
}

.basic-input-element__cover {
  &.basic-input-element__cover--icon {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      width: 18px;
      height: 18px;
      margin-top: -9px;
      background-size: contain;
    }
  }
  &.basic-input-element__cover--icon-clock {
    &:before {
      // TODO: Find a replacement for this fill
      background-image: url('~assets/img/icons/icon_clock.svg?fill=#{$primaryColor}');
    }
  }
  &.basic-input-element__cover--icon-calendar {
    &:before {
      // TODO: Find a replacement for this fill
      background-image: url('~assets/img/icons/icon_calendar.svg?fill=#{$primaryColor}');
    }
  }
}

.basic-input-element {
  .rw-widget-input,
  .rw-filter-input {
    box-shadow: none;
  }
  .rw-state-focus {
    .rw-widget-picker.rw-widget-container {
      border-bottom: none !important;
      box-shadow: none !important;
    }
  }

  .rw-select-bordered {
    border-left: none;
  }
}
