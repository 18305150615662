.player-allocating {
  padding-bottom: 30px;

  @media(min-width: $md) {
    height: calc(100vh - #{$headerHeight} - #{$wrapperPadding});
  }

  .player-allocating__label {
    padding-left: 12px;
    padding-right: 12px;
    text-transform: uppercase;
    font-family: $mainFontBold;
    font-size: 16px;
    line-height: 1.4;
    color: $primaryColor;
    text-align: center;
  }

  .player-allocating__box {
    min-height: 80px;
    max-height: 200px;
    border: 1px solid $fontColorNormal;
    border-radius: $mainBorderRadius;
    flex-grow: 1;
    overflow: auto;

    @media(min-width: $md) {
      max-height: unset;
    }

    &.player-allocating__box--placed {
      background-color: #fff;
    }

    &.player-allocating__box--unassgined {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .player-allocating__boxwrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

}
