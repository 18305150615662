@font-face {
  font-family: 'Roboto_regular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-regular-webfont.eot'), /* IE9 Compat Modes */
  url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-regular-webfont.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-regular-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-regular-webfont.svg#roboto') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto_bold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-bold-webfont.eot'), /* IE9 Compat Modes */
  url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-bold-webfont.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-bold-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-bold-webfont.svg#roboto') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto_light';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-light-webfont.eot'), /* IE9 Compat Modes */
  url('../fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-light-webfont.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-light-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-light-webfont.svg#roboto') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto_italic';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-italic-webfont.eot'), /* IE9 Compat Modes */
  url('../fonts/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-italic-webfont.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-italic-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-italic-webfont.svg#roboto') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto_medium';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-medium-webfont.eot'), /* IE9 Compat Modes */
  url('../fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-medium-webfont.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-medium-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-medium-webfont.svg#roboto') format('svg');
  /* Legacy iOS */
}
