.chart-wrapper {
  margin-bottom: 20px;

  .chart-wrapper__container {
    margin: 0 auto;
  }

  .chart-wrapper__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .chart-wrapper__zoom-button {
    height: 50px;
    padding-left: 17px;
    padding-right: 17px;
  }
}
