.turncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-turncate-text {
  white-space: initial;
  overflow: visible;
  text-overflow: unset;
}

.list-styles-clear {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

@each $pointName, $inWidth in $breakPoints {
  @media(min-width: $inWidth) {
    .turncate-text-#{$pointName} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .reverse-turncate-text-#{$pointName} {
      white-space: initial;
      overflow: visible;
      text-overflow: unset;
    }

    .list-styles-clear {
      list-style-type: none;
      padding-left: 0;
    }
  }
}
