/* FIELDS */
/* ========================================================================== */
/* Fields */
/* --------------------------------------------------------------------------- */
.fields {
  + .fields,
  + .infobox__footer {
    margin-top: 30px;
  }
}

.fields--table {
  display: table;
  width: 100%;
}

.fields--medium-spacing {
  border-spacing: 0 10px;
}

/* Fields row */
/* --------------------------------------------------------------------------- */
.fields__row {
  + .fields__row {
    margin-top: 30px;
  }
}

.fields--small .fields__row {
  + .fields__row {
    margin-top: 15px;
  }
}

.fields--table > .fields__row {
  display: table-row;
}

/* Fields col */
/* --------------------------------------------------------------------------- */
.fields__col {
  position: relative;

  + .fields__col {
    margin-top: 10px;
  }
}

.fields__col--full-width {
  width: 100%;
}

.fields__col--no-wrap {
  white-space: nowrap;
}

.fields--small .fields__col {
  + .fields__col {
    margin-top: 5px;
  }
}

.fields--table > .fields__row > .fields__col {
  display: table-cell;
  padding: 5px;
  vertical-align: top;
}


/* Fields label */
/* --------------------------------------------------------------------------- */
.fields__label {
  color: $fieldsLabelColor;
  font-family: $mainFontLight;
  font-size: 14px;
  line-height: 1.4;
}

.fields__label--head {
  font-family: $mainFontRegular;
  font-size: 16px;
}
.fields__label--title {
  color: $fontColorDark;
  font-family: $mainFontRegular;
  font-size: 18px;
}

/* Fields button */
/* --------------------------------------------------------------------------- */
.fields__button {
  position: relative;
  background: transparent;
  border: none;
}

.fields__button:focus {
  outline: none;
}

.fields-button__label {
  + .fields__icon {
    margin-left: 5px;
  }
}

/* Fields input */
/* --------------------------------------------------------------------------- */
.fields__input {
  display: inline-block;
  margin: 0;
  padding: 10px 15px;
  width: 100%;
  border: 1px solid $fieldsInputBorderColor;
  color: $primaryColor;
  font-family: $mainFontRegular;
  font-size: 14px;
  line-height: 1.4;
  transition: all 0.3s ease-in-out;
}

input[type="text"]:disabled,
.fields__input:disabled {
  color: $disabledFontColor;
  font-family: $mainFontRegular;
  background-color: $disabledBackgroundColor;
  border-bottom: none;
}

.fields__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $fontColorGrey;
}
.fields__input::-moz-placeholder { /* Firefox 19+ */
  color: $fontColorGrey;
}
.fields__input:-ms-input-placeholder { /* IE 10+ */
  color: $fontColorGrey;
}
.fields__input:-moz-placeholder { /* Firefox 18- */
  color: $fontColorGrey;
}

/* Fields input error */
/* --------------------------------------------------------------------------- */
.fields__input.has-error {
  border-color: $tableNegative;
  border-width: 2px;

  + .fields__message {
    padding-top: 5px;
    color: $tableNegative;
    font-size: 14px;
    line-height: 1.4;
  }
}

.fields__input.has-error::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $tableNegative;
}
.fields__input.has-error::-moz-placeholder { /* Firefox 19+ */
  color: $tableNegative;
}
.fields__input.has-error:-ms-input-placeholder { /* IE 10+ */
  color: $tableNegative;
}
.fields__input.has-error:-moz-placeholder { /* Firefox 18- */
  color: $tableNegative;
}
