.main-table {
  /* rowExtension */
  width: 100%;
  border-spacing: 0 3px;
  td,
  th {
    text-align: left;
  }

  th:last-child,
  td:last-child {
    padding-right: 15px;
  }

  th:first-child,
  td:first-child {
    padding-left: 15px;
  }

  th {
    padding: 0 10px 5px;
    font-family: $mainFontRegular;
    font-size: 14px;
    font-weight: normal;
  }

  .cell-bold {
    color: $fontColorDark;
    font-family: $mainFontRegular;
    font-size: 14px;
  }

  .cell-small {
    width: 100px !important;
  }

  tbody {
    tr {
      background-color: #fff;
    }
  }

  td {
    border-top: 1px solid $lightBorderColor;
    border-bottom: 1px solid $lightBorderColor;
    padding: 10px;
    color: $fontColorGrey;
    font-size: 14px;
    font-family: $mainFontRegular;
    &:first-child {
      max-width: 60px;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid $lightBorderColor;
    }

    &:last-child {
      padding-right: 25px;
      border-right: 1px solid $lightBorderColor;

      &.cell-negative {
        border-right: 6px solid $tableNegative;
      }
    }
  }

  .table-spacer {
    background-color: transparent;
    td {
      padding: 2px !important;
      border-top: 1px solid #cbcbcb !important;
      border-right: none;
      border-left: none !important;
    }
  }

  .table-cell-icon {
    width: 20px;
  }

  .table-row-icon {
    position: relative;
    width: 16px;
    height: 9px;
    &:before {
      content: '';
      @include triangle(right, 8px, $primaryColor);
    }
    &.table-row-icon--active {
      &:before {
        content: '';
        @include triangle(bottom, 8px, $primaryColor);
        position: relative;
        //top: -9px;
      }
    }
  }

  /* rowExtension */
  &.main-table--extended {
    border-collapse: collapse;

    tbody tr {
      background-color: $tableExtendedRowColor;

      td {
        border-top: none;
        border-bottom: none;

        &:first-child {
          border-left: 3px solid $greenColorPrimary;
        }
      }
    }

    tbody tr:first-child {
      background-color: #fff;

      td {
        border-top: 1px solid $lightBorderColor;
        border-bottom: 1px solid $lightBorderColor;

        &:first-child {
          border-left: 1px solid $lightBorderColor;
        }

        &:last-child {
          border-right: 1px solid $lightBorderColor;
        }
      }
    }

    .link-button {
      display: inline-block;
      color: $primaryColor;
      transition: all ease 0.2s;
      border-bottom: 1px solid transparent;
      text-transform: uppercase;
      font-family: $mainFontBold;
      &.link-button--disabled {
        cursor: default;
        color: #e1eaec;
        &:focus,
        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
      &:focus,
      &:hover {
        border-bottom: 1px solid $primaryColor;
      }
    }
    .row-clickable:focus,
    .row-clickable:hover {
      background-color: $tableExtendedRowColor;
    }
  }
}

.checkbox {
  width: 16px;
  height: 16px;
}

.checkbox__block {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid $primaryColor;
  border-radius: 4px;
}

.checkbox__block--checked {
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    height: 2px;
    background-color: $primaryColor;
    border-radius: 4px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

/* ico-state */

.ico-state {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 1px solid gray;
  &:before {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-position: center center;
    background-size: contain;
  }
  &.ico-state--positive {
    border: 1px solid $subTablePositive;
    &:before {
      background-image: url('~assets/img/tablecheck.svg');
    }
  }
  &.ico-state--negative {
    border: 1px solid $subTableNegative;
    &:before {
      background-image: url('~assets/img/tablecircle.svg');
    }
  }
}

/* Mainlabel */

.mainlabel {
  padding: 5px 25px;
  border: 1px solid $subTablePositive;
  background-color: $uiInnerBackgroundPrimary;
  border-radius: 8px;
  color: $subTablePositive;
  font-family: $mainFontMedium;
  font-size: 12px;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  &.mainlabel--positive {
    border: 1px solid transparent;
    color: $fontColorLight;
    background-color: $greenColorSecondary;
  }
  &.mainlabel--negative {
    border: 1px solid transparent;
    color: $fontColorLight;
    background-color: $subTableNegative;
  }
}

.row-clickable {
  cursor: pointer;
  transition: 0.2s ease background-color;
}
