.payee {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

  &.payee--money-icon {
    .avatar__inner {
      background-size: 70%;
      background-color: $greenColorLight;
    }
  }
}

.payee__name {
  color: $fontColorDark;
  font-family: $mainFontLight;
  font-size: 16px;
  line-height: 1.4;
}

.payee__profile {
  margin-left: 12px;
  text-align: left;
}

.payee__role {
  color: $fontColorGrey;
  font-family: $mainFontLight;
  font-size: 14px;
  line-height: 1.4;
}

.payee-icon__inner {
  position: relative;
  display: inline-block;

  .avatar {
    width: 64px;
    height: 64px;
  }
}
